/* You can add global styles to this file, and also import other style files */
* {
  font-family: Roboto, Arial, sans-serif
}

body {
  margin: 0;
  height: 100vh;
}

#loginModal {
  position: absolute;
  top: 0;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 9000;
}

#h-badge {
  position: absolute;
  font-size: 10px;
  color: #005f56;
  bottom: 5px;
  margin: 0 6px;
}

.dx-theme-background-color {
  background: #f7f7f7 !important;
}

.logo-loading {
  height: 180px;
  position: fixed;
  top: calc(30vh - 90px);
  right: calc(50vw - 90px);
}

.loading-modal {
  width: 100vw;
  height: 100vh;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.red-txt {
  color: #dd1818;
}

.red-txt>.dx-checkbox-checked {
  background-color: #dd1818;
}

.yellow-txt {
  color: #ff9c00;
}

.green-txt {
  color: rgb(30, 155, 30);
}

.blue-txt {
  color: #184ea2;
}

.blue-txt>.dx-checkbox-checked {
  background-color: #03a9f4;
}

.disabled-txt {
  background-color: #ebebeb;
  color: #747474
}

.dx-hover-gray-bg {
  background-color: #0000000a;
}

.gray-bg {
  background-color: #ebebeb !important;
}

.white-bg {
  background-color: white;
}

.twelve-margin {
  padding: 0 12px;
}

.no-margin h3,
.no-margin p,
.no-margin {
  margin: 0
}

.dx-button.dx-state-hover {
  background-color: none;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.reject-btn {
  color: white !important;
  background: #e12828;
  padding: 0px 8px !important;
  font-size: 12px !important;
  border-radius: 3px;
}

.approve-btn {
  color: white !important;
  background: #47a147;
  padding: 0px 8px !important;
  font-size: 12px !important;
  border-radius: 3px;
}

.clarifications-btn {
  color: white !important;
  background: #183EA2;
  padding: 0px 8px !important;
  font-size: 12px !important;
  border-radius: 3px;
}

.blue-tips {
  color: #183EA2;
}

.yellow-clock {
  color: #ff9c00
}

#h-badge {
  position: absolute;
  font-size: 10px;
  color: #005f56;
  bottom: 5px;
  margin: 0 6px;
}