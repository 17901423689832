:root {
    --base-color: #184ea2;
    --login-color: #184ea2;
    --text-color: rgba(0, 0, 0, .54);
    --complement-color: #ff9c00;
    --menu-container: #363740;
    --menu-text-color: white;
    --border-color: rgba(193, 193, 193, 0.68);
}

.warning {
    display: flex;
    justify-content: center;
    color: var(--warning-color);
    ;
}

.user-symbol {
    background: var(--base-color);
    color: var(--menu-text-color);
}

.ibis-header-popover {
    width: 280px;
}

.submenu-list {
    width: 300px;
}

/* popup styling */
.ibis-edit-popup .dx-toolbar {
    border-top: 1px solid #e0e0e0;
}

.ibis-edit-popup-logo {
    float: left;
    flex: 1;
}

.ibis-edit-popup-logo>img {
    width: 28px;
    height: 28px;
    display: block
}

.ibis-edit-popup-title {
    padding: 10px 10px;
    min-height: 48px;
    background-color: var(--menu-container);
    color: var(--menu-text-color)
}

.ibis-edit-popup-title>h3 {
    flex: 4;
    margin: 5px 6px;
    text-transform: uppercase;
    float: left;
}

.ibis-edit-popup-title dx-button {
    float: right;
}

/* content styling */
.ibis-content-block {
    margin: 0px;
    /* background-color: #f7f7f7; */
    /* padding: 0px 10px; */
}

.ibis-content {
    flex-grow: 1;
    background-color: #f7f7f7;
    padding: 0px 10px;
}

ibis-toolbar {

    .dx-drawer.dx-drawer-opened .dx-drawer-panel-content,
    .dx-drawer.dx-drawer-opened .dx-overlay-content.dx-resizable {
        width: 260px !important;
    }

    .dx-drawer .dx-treeview-toggle-item-visibility {
        display: none;
    }

    .dx-drawer.dx-drawer-opened .dx-treeview-toggle-item-visibility {
        display: block;
    }
}

ibis-header {

    .ibis-company,
    .ibis-department {
        margin: 0 15px;
        min-width: 200px;
        border-right: 1px solid var(--border-color);
        padding: 2px 10px;
        color: var(--text-color);
        display: flex;
        justify-content: space-between;
    }

    .ibis-department {
        width: 300px;
        font-size: 15px;
    }

    .ibis-company-arrow {
        padding: 16px 0;
    }

    .ibis-arrow-right {
        padding: 9px 0px;
        color: var(--text-color);
    }

    /* header styling */
    .dx-toolbar .dx-toolbar-items-container .dx-toolbar-after {
        padding: 0 20px;
    }

    .dx-toolbar .dx-toolbar-items-container {
        height: 56px !important;
    }

    .ibis-header-item {
        text-align: center;
    }

    .ibis-header-icon {
        width: 30px;
        font-size: 20px;
        color: var(--complement-color);
        cursor: pointer;
    }

    .ibis-header-icon.ibis-user-icon {
        color: var(--base-color);
    }

    .dx-toolbar {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    .rotate-arrow {
        transform: rotate(90deg);
    }

    .ibis-header-toggle,
    .ibis-header-toggle dx-button.dx-state-focused,
    .ibis-header-toggle dx-button.dx-state-hover {
        background-color: var(--base-color);
    }

    .ibis-header-toggle dx-button .dx-icon {
        color: var(--menu-text-color);
    }

    .ibis-header-toggle dx-button {
        margin-left: 12px;
        margin-right: 4px;
        background-color: var(--base-color);
    }

    .ibis-header-toggle dx-button .dx-icon {
        color: var(--menu-text-color);
    }

}

ibis-menu {
    .dx-treeview .dx-treeview-node.dx-state-selected .dx-item-content.dx-treeview-item-content {
        color: var(--base-color);
    }

    .dx-treeview .dx-scrollable-content>ul>li:first-child {
        background-color: var(--base-color);
    }

    .dx-treeview-item .dx-treeview-item-content span {
        padding-bottom: 8px;
    }

    .dx-treeview .dx-scrollable-content>ul>li:first-child>.dx-item.dx-treeview-item {
        height: 56px;
        font-size: 17px;
    }

    .dx-treeview .dx-scrollable-content>ul>li:first-child>.dx-item.dx-treeview-item>div {
        padding: 7px 0;
    }

    .dx-treeview-toggle-item-visibility,
    .dx-item-content.dx-treeview-item-content,
    .dx-texteditor-input {
        color: var(--menu-text-color);
    }

    .dx-treeview .dx-scrollable-content>ul>li:first-child .dx-treeview-toggle-item-visibility {
        margin-top: 9px;
    }

    .dx-treeview .dx-treeview-item.dx-state-hover {
        background-color: #00000038 !important;
    }

    .ibis-parent-selected>.dx-item-content.dx-treeview-item-content {
        color: var(--base-color);
    }

    .menu-container .dx-treeview .dx-treeview-node[aria-level="1"] {
        border-bottom-width: 0px !important;
    }

    .menu-container .dx-treeview .dx-treeview-item .dx-icon {
        // padding-left: 16px;
        font-size: 18px;
    }

    .menu-container .dx-treeview .dx-treeview-item {
        padding: 0px;
    }

    .menu-container .dx-treeview .dx-treeview-item .ibis-item-child,
    .menu-container .dx-treeview .dx-treeview-item .ibis-item-parent {
        padding: 10px 0px;
    }

    .menu-container .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected>.dx-treeview-item {
        background-color: #f7f7f7 !important;
    }

    .menu-container .dx-treeview-node .dx-treeview-item {
        flex-direction: row-reverse;
    }
}

.ibis-menu-container {
    background-color: var(--menu-container);
}

.ibis-user-icon {
    margin-top: 5px;
    margin-right: -5px;
    margin-left: 5px;
}

/* popup styling */
.ibis-edit-popup .dx-toolbar {
    border-top: 1px solid #e0e0e0;
}

.ibis-edit-popup-logo {
    float: left;
    flex: 1;
}

.ibis-edit-popup-logo>img {
    width: 28px;
    height: 28px;
    display: block
}

.ibis-edit-popup-title {
    padding: 10px 10px;
    min-height: 46px;
}

.ibis-edit-popup-title>h3 {
    flex: 4;
    margin: 5px 6px;
    text-transform: uppercase;
    float: left;
}

.ibis-edit-popup-title dx-button {
    float: right;
}

/* content styling */
.ibis-content-block {
    margin: 0px;
    /* background-color: #f7f7f7; */
    padding: 0px 10px;
}

.ibis-content {
    flex-grow: 1;
    background-color: #f7f7f7;
}

/* top-right - user info*/
.ibis-user-info {
    border-left: 1px solid var(--border-color);
    display: flex;
    padding: 10px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.ibis-fullName-text {
    margin: 0 10px;
    color: var(--text-color);
}

.ibis-role-text {
    font-size: 12px;
    margin: 0 10px;
    color: var(--text-color);
}

/* footer styling */
ibis-footer {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0px;
    color: var(--text-color);
}

.ibis-footer-copy {
    width: 50%;
    text-align: right;
    display: inline-block;
}

.ibis-footer-version {
    width: 50%;
    display: inline-block;
}

#footer {
    padding: 7px 0px;
    margin: 10px 15px 0px;
    font-size: 12px;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

/* breadcrumb styling */
ibis-breadcrumb {
    min-width: 300px;
    display: flex;
    padding: 10px 0px;
    font-weight: bold;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    height: 41px;
    /* background-color: #f7f7f7; */
    color: var(--text-color);
}

.ibis-breadcrumb-item {
    display: flex;
    font-size: 14px;
    line-height: 1.2857;
}

.ibis-breadcrumb-item p {
    margin: 0px 5px;
}

.ibis-breadcrumb-icon {
    height: 18px;
}

/* ibis-shortcut */
ibis-shortcut {
    text-align: right;

    .ibis-shortcut {
        margin-top: 5px;
    }

    .dx-tabs {
        text-align: right;
    }

    .dx-tabs,
    .dx-tab {
        background-color: transparent;
    }

    .dx-tabs .dx-tab {
        display: inline-block;
    }
}

.ibis-content-block {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
}



/* ibis-header .border-top{
    border-top: 1px solid #777;
  }
  
ibis-header .border-bottom{
    border-bottom: 1px solid rgb(138, 138, 138);
} */
/* .dx-overlay-wrapper.dx-popup-wrapper.dx-overlay-modal.dx-overlay-shader{
    z-index: 1502 !important;
} */


#logoImg {
    background-image: url(./assets/img/logo.png);
    position: relative;
    margin: auto;
    margin-bottom: 50px;
    height: 100px;
    width: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}

/* LOGIN */
ibis-login {
    #ibis-login-box {
        left: 25vw !important;
        width: 50vw !important;
    }

    #ibis-login-box.isLoginVisible {
        left: calc(50vw - 150px) !important;
        width: 300px !important;
    }

    #logoImg::after {
        // content: "BuyEx";
        font-size: 22px;
        font-weight: 500;
        color: var(--login-color);
        position: absolute;
        bottom: -36px;
        text-transform: uppercase;
        position: absolute;
        left: calc(50% - 150px);
        width: 300px;
        text-align: center;
    }

    #login-form {
        opacity: 1 !important;
        width: 300px;
        margin: 0px auto;

        .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
            padding-top: 0;
        }
    }

    .ibis-sign-in .dx-button {
        background: var(--login-color);
        width: 100%;
        border: 1px solid var(--login-color);
        color: var(--menu-text-color);
        /* border-radius: 40px; */
    }

    .ibis-sign-in .dx-button-text {
        line-height: 17px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: .04em;
    }

    .ibis-apps-list .dx-item.dx-list-item {
        margin: 5px 0;
        background-color: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    .dx-field-item-content:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs) {
        padding: 0px;
    }

    .ibis-apps-list .dx-item.dx-list-item .redirect-link,
    .ibis-apps-list .dx-item.dx-list-item .redirect-name {
        margin: 0;
        padding: 0;
    }

    .ibis-list-box {
        margin: 32px auto 0px;
        width: 170px;
    }

    .ibis-list-box .ibis-select-list {
        margin: 15px 0 30px;
    }

    h4,
    h3 {
        text-align: center;
        margin: 0;
    }
}


ibis-footer {
    width: 100%;
}